import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Plural } from './plural';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "plural"
    }}>{`Plural`}</h1>
    <p>{`The plural component is used to create a message which changes a noun based on the numeric input.
Different locales have different rules for plurality. For instance, in English there are two types of plurals:`}</p>
    <ol>
      <li parentName="ol">{`One e.g. 1 apple`}</li>
      <li parentName="ol">{`Other e.g. 0 apples, 4 apples`}</li>
    </ol>
    <p>{`However, in Russian there are several different plural rules.`}</p>
    <ol>
      <li parentName="ol">{`Numbers ending in 1 (except 11) e.g. одна девушка - one girl`}</li>
      <li parentName="ol">{`Numbers ending in 2,3,4 (except 12, 13, 14) e.g. три собаки - three dogs`}</li>
      <li parentName="ol">{`Numbers ending in : 5,6,7,8,9,0 (and the exceptions above) e.g. девять собак - nine dogs`}</li>
      <li parentName="ol">{`Genitive Case (possessive pluralization). (Lots of rules)`}</li>
      <li parentName="ol">{`General Case.`}</li>
    </ol>
    <p>{`Managing all these cases is difficult. Fortunately, we have the `}<inlineCode parentName="p">{`Plural`}</inlineCode>{` component that handles all of this for us`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import \\* as React from 'react'
import { Plural } from '@patreon/stele'

function MyComponent(props) {
return (

<Text intl>
    {props.userName} has
    <Plural value={props.appleCount} one="an apple" other="# apples" />
</Text>
) }

`}</code></pre>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Plural} mdxType="Props" />
    <h2 {...{
      "id": "example-message"
    }}>{`Example message`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`{userName} has {appleCount, plural,
    one {apple}
    other {# apples}
}
`}</code></pre>
    <h2 {...{
      "id": "playground"
    }}>{`Playground`}</h2>
    <Playground __position={1} __code={'() => {\n  const [count, setCount] = React.useState(1)\n  return (\n    <>\n      <div>\n        <input\n          value={count}\n          onChange={e => setCount(e.target.value)}\n          type=\"number\"\n        />\n      </div>\n      <p>\n        The apple picker has\n        <Plural\n          value={count}\n          zero=\"no apples\"\n          one=\"an apple\"\n          other=\"# apples\"\n        />\n      </p>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Plural
    }} mdxType="Playground">
    {() => {
        const [count, setCount] = React.useState(1);
        return <>
                <div>
                    <input value={count} onChange={e => setCount(e.target.value)} type="number" />
                </div>
                <p>
                    The apple picker has
                    <Plural value={count} zero="no apples" one="an apple" other="# apples" mdxType="Plural" />
                </p>
            </>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      